import { Box, Card, Group, Stack, Text, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

import { BiomarkerSummary } from '@/charts';
import type { Biofunction } from '@/types';

import classes from './_NavGroup.module.css';
interface Props {
  biofunctions: Biofunction[];
  value: number;
  onSelect: (index: number) => void;
}

export function NavGroup({ onSelect, value, biofunctions }: Props) {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { t } = useTranslation();

  return (
    <Stack gap="xs">
      <Title lineClamp={1} size="h2">
        {t('pathways.title')}
      </Title>

      {isMobile && (
        <Text size="xs>">{t('pathways.mobile_view.select_pathway')}</Text>
      )}

      {biofunctions.map((biofunction, index) => (
        <Card
          key={index}
          onClick={() => {
            onSelect(index);
          }}
          className={classes.navLink}
          data-active={!isMobile && value === index}
          style={{ cursor: 'pointer' }}
        >
          <Group justify="space-between">
            <Text tt="capitalize" truncate w="60%">
              {biofunction.biofunction_name}
            </Text>
            <Box w="30%" h="100%" style={{ paddingTop: '10px' }}>
              <BiomarkerSummary data={biofunction.all_measure_counts} />
            </Box>
          </Group>
        </Card>
      ))}
    </Stack>
  );
}
