import { Container, Grid, Stack } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Back, Empty } from '@/components';
import {
  client_reports_biofunctions_path,
  client_tests_index_path,
} from '@/routes';
import type { Biofunction } from '@/types';

import { Menu } from '../_Menu';
import { BiofunctionDetails } from './_Biofunction';
import { NavGroup } from './_NavGroup';

interface Props {
  biofunctions: Biofunction[];
  reportId: number;
}

export default function Pathways({ biofunctions, reportId }: Props) {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { t } = useTranslation();
  const [selectedBiofunction, setSelectedBiofunction] = useState(0);

  const [showBiofunctionDetail, setShowBiofunctionDetail] = useState(false);

  const handleSelect = (index: number) => {
    setSelectedBiofunction(index);
    if (isMobile) {
      setShowBiofunctionDetail(true);
    }
  };

  const showNavGroup = !isMobile || !showBiofunctionDetail;
  const showBackButton = isMobile && showBiofunctionDetail;
  const showBiofunctionDetails = !isMobile || showBiofunctionDetail;

  return (
    <Container size="lg">
      <Stack gap="xl">
        <Menu />
        {biofunctions?.length > 0 ? (
          <Grid gutter="xl">
            {showNavGroup && (
              <Grid.Col span={isMobile ? 12 : 4}>
                <NavGroup
                  biofunctions={biofunctions}
                  value={selectedBiofunction}
                  onSelect={handleSelect}
                />
              </Grid.Col>
            )}

            {showBackButton && (
              <Grid.Col span={12}>
                <Back
                  label={t('pathways.mobile_view.back_button')}
                  to={client_reports_biofunctions_path()}
                />
              </Grid.Col>
            )}

            {showBiofunctionDetails && (
              <Grid.Col span={isMobile ? 12 : 8}>
                <BiofunctionDetails
                  reportId={reportId}
                  biofunction={biofunctions[selectedBiofunction]}
                />
              </Grid.Col>
            )}
          </Grid>
        ) : (
          <Empty
            headline={t('report.no_data.headline')}
            strapline={t('report.no_data.strapline')}
            href={client_tests_index_path()}
          />
        )}
      </Stack>
    </Container>
  );
}
